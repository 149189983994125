import React, { Navigate } from 'react-router';
import { useSession } from '../auth/useSession';

// Config
import config from '../config.json';

/* eslint-disable camelcase */
const PrivateRoute = ({
    previewToken,
    clientId,
    children
}) => {

    const { session } = useSession();
    const preview_token = config.preview_token;

    if (['local', 'development', 'test', 'staging'].includes(process.env.REACT_APP_ENV)) {
        // This part is form the preview in admin CMS pages editor
        if ((previewToken && previewToken === preview_token) &&
            (clientId && config.accepted_clients.includes(clientId))) {
            return children;
        }

        return session ? children : <Navigate to='/lockscreen' />;
    }

    return children;
};

export default PrivateRoute;