import React, { Container, Col, Row } from 'react-bootstrap';

const HowFindUs = (props) => {

    const data = props.props;

    return (
        <>
            <Container
                className="py-5"
            >
                <Row>
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: data.text}} />

                        {/* <Row className="mb-3">
                            <label>orari di apertura</label>
                        </Row>
                        <Row>
                            <Col>lunedi - venerdi</Col>
                            <Col>08:30 - 18:30</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>sabato</Col>
                            <Col>08:30 - 17:00</Col>
                        </Row>
                        <Row className="mb-3">
                            <label>orario continuato, ampio parcheggio</label>
                        </Row>
                        <Row className="mb-3 pt-4">
                            <label>come raggiungerci</label>
                        </Row>
                        <Row>
                            <label>uscita autostrada bellinzona sud</label>
                            <label>300m direzione Bellinzona</label>
                        </Row> */}
                    </Col>
                    <Col>
                        <div dangerouslySetInnerHTML={{
                            __html: '<iframe \
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2763.301734141397!2d8.992752572433684!3d46.164649013472335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47844a751c2cc8c9%3A0x85bf060ca731670f!2sGarden%20Center%20B%C3%BCrgi!5e0!3m2!1sit!2sch!4v1662455065580!5m2!1sit!2sch" \
                            width="600" \
                            height="450" \
                            style="border:0;" \
                            allowfullscreen="" \
                            loading="lazy" \
                            referrerpolicy="no-referrer-when-downgrade"></iframe>'}} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default HowFindUs;