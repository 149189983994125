import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { GetNavigationMenu } from '../methods/navigation';

// Styles
import '../styles/NavigationSection.css';

// Fake data
// import data from '../data/fake_navigation.json';

const subItemsStyle = {
	paddingLeft: '10px',
};

const getItems = (items, level) => {

	const result = [];

	// console.log('getItems', items);

	if (items?.length > 0) {
		items.map(item => {
			// console.log('getItems => item', item.title, item.children);
			result.push(
				<NavigationItem
					key={uuidv4()}
					item={item}
					level={level + 1}
				/>
			);
		});
	}

	return result;
};

const NavigationItem = ({
	item,
	level,
}) => {

	const { search } = useLocation();

	// console.log('NavigationItem', item.title, item.children);

	return (
		<>
			<div>
				<Link className={`navigation-section navigation-section-link ${window.location.pathname === item.url ? 'active' : ''}`} to={`${item.url}${search}`}>{item.title}</Link>
			</div>
			<div style={subItemsStyle}>
				{item.children && item.children.length > 0 ?
					getItems(item.children, level) : ''
				}
			</div>
		</>
	);
};

const NavigationItems = ({
	items,
}) => {

	const result = [];

	// console.log('NavigationItems', items);

	items.map(item => {
		result.push(<NavigationItem
			key={uuidv4()}
			item={item}
			level={1}
		/>);
	});

	return result;
};

const NavigationCard = ({
	item,
}) => {

	const { search } = useLocation();

	// console.log('NavigationCard', item);

	return (
		<Card className='border-0'>
			<Card.Body>
				<Card.Title>
					<Link className={`navigation-section navigation-section-link ${window.location.pathname === item.url ? 'active' : ''}`}to={`${item.url}${search}`}>{item.title}</Link>
					<div style={subItemsStyle}>
						{item.children.length > 0 && <NavigationItems items={item.children} />}
					</div>
				</Card.Title>
			</Card.Body>
		</Card>
	);
};

const NavigationCol = ({
	item,
	colWidth,
}) => {

	// console.log('NavigationCol', item);

	return (
		<Col sm={colWidth}>
			<NavigationCard item={item} />
		</Col>
	);
};

const NavigationRow = ({
	items,
	colWidth,
}) => {

	const renderCols = [];

	items.map(item => {

		renderCols.push(<NavigationCol
			key={uuidv4()}
			item={item}
			colWidth={colWidth}
		/>);
	});

	return (
		<Row>
			{renderCols}
		</Row>
	);
};

const NavigationContainer = ({
	items,
}) => {

	const maxCols = 4;
	const cols = items.length < maxCols ? items.length : maxCols;
	const colWidth = 12 / cols;
	const rows = Math.ceil(items.length / maxCols);

	// console.log('NavigationContainer');
	// console.log('cols', cols);
	// console.log('colWidth', colWidth);
	// console.log('rows', rows);

	const renderRows = [];
	const currentItems = [...items];
	for (let row = 1; row <= rows; row++) {

		renderRows.push(<NavigationRow
			key={uuidv4()}
			items={currentItems.splice(0, cols)}
			colWidth={colWidth}
		/>);
	}

	return (
		<Container>
			{renderRows}
		</Container>
	);
};

const NavigationSection = ({
	visible,
}) => {

	const [navigation, setNavigation] = useState();
	const [renderContainer, setRenderContainer] = useState();

	useEffect(() => {
		if (!navigation) {
			GetNavigationMenu()
				.then(response => {
					// console.log('getNavigationMenu', response);
					setNavigation(response);
					setRenderContainer(<NavigationContainer items={response} />);
				});

			// From fake data
			// setNavigation(data);
			// setRenderContainer(<NavigationContainer items={data} />);
		}
	});

	return (
		<>
			{visible ? renderContainer : ''}
		</>
	);
};

export default NavigationSection;