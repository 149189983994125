import React from 'react';
import { Image } from 'react-bootstrap';

const Status204 = () => {

    return (
        <div
            style={{
                marginTop: '20rem',
                marginBottom: '20rem',
            }}
        >
            <div className='d-flex justify-content-center'>
                <Image
                    fluid
                    src={`${window.location.origin}/images/flower-outline.svg`}
                    width='100'
                    color='#ee1784'
                />
                <div className='align-self-center fs-1 ms-3 fw-bold'>204 Nessun contenuto</div>
            </div>
        </div>
    );
};

export default Status204;
