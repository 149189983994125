/**
 * Determine if a number is odd or even and return true if is even otherwise false
 * @param {The number to check if is odd or even} number
 */
export const _isEven = (number) => {

    return number % 2 === 0 ? true : false;
};

/**
 * Determine if a number is odd or even and return left or right
 * @param {The number to check if is odd or even} number
 * @param {The start position, by default 0 equal Left and 1 equal Right} start
 */
export const _leftRightByNumber = (number, start) => {

    let isEven = number % 2 === 0 ? true : false;

    if (start && start !== 0) isEven = !isEven;

    return isEven ? 'left' : 'right';
};