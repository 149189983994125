import { RunQuery } from '../utility/Fetch';

/**
 * Get the Homepage content by a given language
 * @param {The language of the desired content, if not provided the default will be taken (italian)} language
 */
export const GetHomePageData = async (language) => {

    // If the language is not provided is it be set to italian => 0
    if (!language) language = 0;

    const response = await RunQuery({
        path: 'v1/CMS/HomePage',
        callType: 'get',
        data: {
            language: language
        },
        withCredentials: true
    })
    .then(response => {
        // console.log('RunQuery > GetHomePageData > response:', response);

        if (response.Error.ErrorCode)
        {
            return response.Error;
        }

        return response.Result;
    });

    return await response;
};

/**
 * Get a CMS page
 * @param {The url of desired page} url
 * @param {The language of desired page, if not provided the default is 0 (it)} language
 */
export const GetCMSPage = async (url, language) => {

    // If the language is not provided is it be set to italian => 0
    if (!language) language = 0;

    if (!url) {
        console.log('The GetCMSPage need an URL to be called');
        return null;
    }

    const response = await RunQuery({
        path: 'v1/CMS/Page',
        callType: 'get',
        data: {
            url: url,
            language: language
        },
        withCredentials: true
    })
    .then(response => {
        // console.log('RunQuery > GetCMSPage > response:', response);

        if (response.Error.ErrorCode)
        {
            return response.Error;
        }

        return response.Result;
    });

    return await response;
};