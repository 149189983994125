import React, { useState, useEffect } from 'react';

// Components
import Carousel from '../components/Carousel';
import Mosaic from '../components/Mosaic';
import DetailElements from '../components/DetailElements';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

// Data
import HowFindUs from '../components/HowFindUs';

// Methods
import { GetHomePageData } from '../methods/cms';

const HomePage = () => {

	const language = 0;

	const [data, setData] = useState();

	useEffect(() => {
		if (!data) {
			GetHomePageData(language)
				.then(response => {
					if (data !== response.content) {
						setData(response.content);
					}
				});
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<div>
			{data ?
				<>
					<Carousel props={data?.carousel} />
					<Mosaic props={data?.mosaic} />
					<DetailElements props={data?.detail_elements} />
					<ContactForm props={data?.contact_form} />
					<HowFindUs props={data?.how_find_us} />
					<Footer data={data?.footer} />
				</>
				: ''}
		</div>
	);
};

export default HomePage;