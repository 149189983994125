import React from 'react';

const About = () => {

	return (
		<div>
			<h1>About</h1>
		</div>
	);
};

export default About;