import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import Footer from '../components/Footer';
import ModuleContainer from '../components/ModuleContainer';

// Methods
import { GetCMSPage } from '../methods/cms';
import Status204 from './statuses/204';

const CMSPage = ({
	url,
	language,
}) => {

	const [data, setData] = useState();

	// console.log('CMSPage', url, language, data);

	useEffect(() => {
		if (!data || data.url !== url) {
			GetCMSPage(url, language)
				.then(response => {
					if (!response) return null;

					// console.log('cms', response);

					if (data !== response) {
						setData(response);

					}
				});
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [url]);

	return (
		<div>
			{data ?
				<>
					{data?.modules?.length > 0 ?
						data.modules.map(module => (
						<ModuleContainer key={uuidv4()} data={module} />
					)) : <Status204 />}
					{data.footer ? <Footer data={data?.footer_content} /> : ''}
				</>
				: ''}
		</div>
	);
};

export default CMSPage;