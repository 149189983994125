import React from 'react';


const TextModuleComponent = ({ data }) => {

	// console.log('TextModuleComponent', data?.content);

	return (
		<div className='py-3'>
			<div dangerouslySetInnerHTML={{ __html: data?.content }} />
		</div>
	);
};

export default TextModuleComponent;
