import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../auth/useSession';
import { Login } from '../methods/admin';

// Styles
import '../styles/Lockscreen.css';

const Lockscreen = () => {

    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [resultError, setResultError] = useState(null);

    // React router DOM
    const navigate = useNavigate();

    // Session object
    const { session, setSession } = useSession();

    // if (session) {
    //     window.location.href = '/';
    //     return;
    // }

    const handleLogin = async event => {

        event.preventDefault();

        const email = event.target.email.value;
        const password = event.target.password.value;

        if (!email) {
            setEmailError('Il campo email dev\'essere compilato');
            return;
        }

        if (!password) {
            setPasswordError('Il campo password dev\'essere compilato');
            return;
        }

        const response = await Login(email, password);

        if (!response.activated) {
            setResultError('Account non ancora attivato');
            return;
        }

        setSession(response);
    };

    useEffect(() => {
        if (session) {
            navigate('/');
            return;
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [session]);

    return (
        <Container fluid className='ls-container vh-100'>
            <Row className='h-100'>
                <Col></Col>
                <Col className='d-flex ls-col' xs={12} md={6}>
                    <Card className='align-self-center ls-card'>
                        <Card.Body>
                            <Card.Title>Lockscreen Garden Bürgi Website</Card.Title>
                            <Form onSubmit={event => handleLogin(event)}>
                                <Alert variant='danger' className={!resultError ? 'd-none' : ''}>{resultError}</Alert>
                                <Form.Group className='mb-3' controlId='email'>
                                    <Form.Label>email</Form.Label>
                                    <Form.Control type='email' placeholder='email' className={emailError ? 'is-invalid' : ''} />
                                    <Form.Control.Feedback type='invalid'>{'emailError'}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='mb-3' controlId='password'>
                                    <Form.Label>password</Form.Label>
                                    <Form.Control type='password' placeholder='password' className={passwordError ? 'is-invalid' : ''} />
                                    <Form.Control.Feedback type='invalid'>{passwordError}</Form.Control.Feedback>
                                </Form.Group>
                                {/* <Form.Group className='mb-3' controlId='keepMeLoggedIn'>
                                    <Form.Check type='checkbox' label={getTranslation('keep_me_logged_in')} />
                                </Form.Group> */}
                                <Button variant='primary' type='submit'>Accedi</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
            <div className='ls-credits'>
                <label>developed by <a href='https://badsoft.ch'>BadSoft</a> Ticino</label>
            </div>
        </Container>
    );
};

export default Lockscreen;