import React, { useEffect, useState } from 'react';

// Modules
import CarouselModuleComponent from './modules/CarouselModule';
import ImageModuleComponent from './modules/ImageModule';
import TextImageModuleComponent from './modules/TextImageModule';
import TextModuleComponent from './modules/TextModule';

const getComponent = (data) => {

	switch (data?.type) {
		case 'carousel':
			return <ModuleSection data={data?.moduleOptions}><CarouselModuleComponent data={data} /></ModuleSection>;
		case 'text':
			return <ModuleSection data={data?.moduleOptions}><TextModuleComponent data={data} /></ModuleSection>;
		case 'textimage':
			return <ModuleSection data={data?.moduleOptions}><TextImageModuleComponent data={data} /></ModuleSection>;
		case 'image':
			return <ModuleSection data={data?.moduleOptions}><ImageModuleComponent data={data} /></ModuleSection>;
		default:
			return null;
	}
};

const ModuleSection = ({
	data,
	children,
}) => {

	const [classes, setClasses] = useState();
	const [styles, setStyles] = useState();
	const [backgroundStyle, setBackgroundStyle] = useState();

	useEffect(() => {
		if (data) {

			let _classes = '';
			const _styles = {};
			const _backgroundStyle = {};


			if (data.horizontalMarginsType === 'custom') {
				_styles.marginLeft = data.horizontalMargins;
				_styles.marginRight = data.horizontalMargins;
			}
			else if (data.horizontalMarginsType === 'fluid') {
				_classes += ' container-fluid px-0';
			}
			else {
				_classes += ' container';
			}

			if (data.upperMarginType === 'custom') {
				_styles.marginTop = `${data.upperMargin}px`;
			}
			else if (data.upperMarginType === 'none') {
				_classes += ' mt-0';
			}
			else {
				_styles.marginTop = `${data.upperMargin}`;
			}

			if (data.bottomMarginType === 'custom') {
				_styles.marginBottom = `${data.bottomMargin}px`;
			}
			else if (data.bottomMarginType === 'none') {
				_classes += ' mb-0';
			}
			else {
				_styles.marginBottom = `${data.bottomMargin}`;
			}

			if (data.backgroundIdentifier !== 'default') {
				_backgroundStyle.backgroundColor = data.backgroundColor;
			}
			else {
				_backgroundStyle.backgroundColor = '#ffffff';
			}

			setClasses(_classes);
			setStyles(_styles);
			setBackgroundStyle(_backgroundStyle);
		}
	}, [data]);

	return (
		<div style={backgroundStyle}>
			<div className={classes && classes} style={styles && styles}>
				{children}
			</div>
		</div>
	);
};

const ModuleContainer = ({ data }) => {

	const [module, setModule] = useState();

	useEffect(() => {
		if (!module || data !== module) {
			setModule(data);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	// console.log('module', data);

	return (
		<div>
			{module && getComponent(module)}
		</div>
	);
};

export default ModuleContainer;