import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';

// Utility
import { _leftRightByNumber } from '../utility/OddEvenNumber';

const _Image = ({ image, position }) => {

    return (
        <Col
            className={position}
        >
            <Image
                src={image}
                fluid={true}
            />
        </Col>
    );
};

const _Text = ({ title, text, link, button }) => {

    return (
        <Col
            className="pt-5"
        >
            <h1>{title}</h1>
            <label
                className="d-block mb-3 text-black-50"
            >{text}</label>
            <Button
                link={link}
            >{button}</Button>
        </Col>
    );
};

const DetailElement = ({ element, side }) => {

    // console.log('element', element);

    return (
        <Row
            className="mb-5">
            <_Image
                position={side !== 'left' ? 'order-1' : ''}
                image={element.image}
            />
            <_Text
                title={element.title}
                text={element.text}
                button={element.button}
                link={element.link}
            />
        </Row>
    );
};

const DetailElements = (props) => {

    const detailElements = props.props;

    const [elements, setElements] = useState();
    const [numberOfElements, setNumberOfElements] = useState();

    useEffect(() => {

        if (elements !== detailElements.elements) {
            setElements(detailElements.elements);
        }

        if (numberOfElements !== detailElements.elements_count) {
            setNumberOfElements(detailElements.elements_count);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [props, detailElements]);

    return (
        <>
            <Container
                className="py-5"
            >
                {elements && elements.map((element, index) =>
                    <DetailElement
                        key={`detail_element_${index}`}
                        element={element}
                        side={_leftRightByNumber(index)}
                    />
                )}
            </Container>
        </>
    );
};

export default DetailElements;