import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const MosaicCard = ({ item }) => {

    return (
        // <Card style={{ width: '18rem' }}>
        <Card>
            <Card.Img variant="top" src={item.image} />
            <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text>{item.caption}</Card.Text>
                <Button
                    variant="primary"
                    href={item.link}
                >{item.button}</Button>
            </Card.Body>
        </Card>
    );
};

const MosaicRow = ({ items }) => {

    return (
        <Row
            className="mb-3">
            {items.map((item, _index) =>
                <Col key={item.identifier}>
                    <MosaicCard item={item} />
                </Col>
            )}
        </Row>
    );
};

const Mosaic = (props) => {

    const mosaic = props.props;

    const [elements, setElements] = useState();
    const [numberOfElements, setNumberOfElements] = useState();

    useEffect(() => {

        if (elements !== mosaic.elements) {
            setElements(mosaic.elements);
        }

        if (numberOfElements !== mosaic.elements_count) {
            setNumberOfElements(mosaic.elements_count);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [props, mosaic]);

    return (
        <>
            <Container
                className="py-5"
            >
                {elements && elements.map((element, index) =>
                    <MosaicRow
                        key={`row_${index}`}
                        items={element}
                    />
                )}
            </Container>
        </>
    );
};

export default Mosaic;