import { RunQuery } from '../utility/Fetch';

// Config
import config from '../config';

/**
 * Login to the FronEnd
 * @param {The email} email
 * @param {The password} password
 */
export const Login = async (email, password) => {

    //#region Check parameters
    if (!email) {
        return {
            ErrorCode: 1,
            ErrorMessage: `Devi inserire ${_getErrorMessage('email')}`,
        };
    }

    if (!password) {
        return {
            ErrorCode: 1,
            ErrorMessage: `Devi inserire ${_getErrorMessage('password')}`,
        };
    }
    //#endregion

    const data = {
        email: email,
        password: password,
        source: config?.source
    };

    const response = await RunQuery({
        path: 'v1/Admin/Login',
        callType: 'post',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > Login > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

/**
 * Logout the current logged user
 */
export const Logout = async () => {

    return null;
};

const _getErrorMessage = (type) => {

    switch (type) {
        case 'email':
            return 'un indirizzo email';
        case 'password':
            return 'una password';
        default:
            return 'i dati';
    }
};