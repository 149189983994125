import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const CarouselItem = ({ item }) => {

    if (!item) return;

    return (
        <Carousel.Item
            identifier={item.identifier}>
            <img
                className="d-block w-100"
                src={item.image}
                alt={item.text}
            />
            <Carousel.Caption>
                <h3>{item.text}</h3>
                <p>{item.caption}</p>
            </Carousel.Caption>
        </Carousel.Item>
    );
};

const _Carousel = (props) => {

    const carousel = props.props;

    const [elements, setElements] = useState();
    const [numberOfElements, setNumberOfElements] = useState();

    useEffect(() => {

        if (elements !== carousel.elements) {
            setElements(carousel.elements);
        }

        if (numberOfElements !== carousel.elements_count) {
            setNumberOfElements(carousel.numberOfElements);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [props, carousel]);

    return (
        <>
            <Carousel
                indicators={carousel.options.dots ?? false}
                controls={carousel.options.controls ?? false}
                interval={carousel.options.interval ?? null}
                variant={carousel.options.variant ?? 'dark'}
            >
                {elements ? elements.map(element => (
                    // <CarouselItem key={element.identifier} item={element} />
                    <Carousel.Item
                        key={element.identifier}
                        identifier={element.identifier}>
                        {element.link ? <a href={element.link}>
                            <img
                                className="d-block w-100"
                                src={element.image}
                                alt={element.text ?? ''}
                            />
                            <Carousel.Caption>
                                {element.text ? <h3>{element.text}</h3> : ''}
                                {element.caption ? <p>{element.caption}</p> : ''}
                            </Carousel.Caption>
                        </a> :
                            <>
                                <img
                                    className="d-block w-100"
                                    src={element.image}
                                    alt={element.text ?? ''}
                                />
                                <Carousel.Caption>
                                    {element.text ? <h3>{element.text}</h3> : ''}
                                    {element.caption ? <div dangerouslySetInnerHTML={{ __html: element.caption }}></div> : ''}
                                </Carousel.Caption>
                            </>
                        }
                    </Carousel.Item>
                )) : ''}
            </Carousel>
        </>
    );
};

export default _Carousel;