import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, FloatingLabel } from 'react-bootstrap';

// Methods
import { ContactFormSend } from '../methods/contactForm';

const _Form = ({ data, validated, handleSubmit }) => {

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="daform"
        >
            <FloatingLabel
                controlId="email"
                label={data && data.labels?.email}
                className="mb-3"
            >
                <Form.Control
                    type="email"
                    placeholder={data && data.labels?.sample_email}
                    required
                />
                <Form.Control.Feedback tooltip type="invalid">
                    {data && data.labels?.email_required}
                </Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel
                controlId="phone"
                label={data && data.labels?.phone}
                className="mb-3"
            >
                <Form.Control type="text" placeholder={data && data.labels?.phone} />
            </FloatingLabel>
            <FloatingLabel
                controlId="message"
                label={data && data.labels?.message}
                className="mb-3"
            >
                <Form.Control
                    as="textarea"
                    placeholder={data && data.labels?.message}
                    style={{ height: '150px' }}
                    required
                />
                <Form.Control.Feedback tooltip type="invalid">
                    {data && data.labels?.message_required}
                </Form.Control.Feedback>
            </FloatingLabel>
            <Button variant="primary" type="submit">
                {data && data.labels?.send}
            </Button>
        </Form>
    );
};

const _FormSubmitted = ({ data, _handleClick }) => {

    return (
        <>
            <h2 className="text-success align-self-center">{data && data.labels?.form_submitted}</h2>
            {/* <Button
                onClick={handleClick}
            >
                {data && data.labels?.new_form}
            </Button> */}
        </>
    );
};

const ContactForm = (props) => {

    const formData = props.props;

    // console.log('formData', formData);

    const [data, setData] = useState();

    useEffect(() => {

        if (data !== formData) {
            setData(formData);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [props, formData]);

    //#region Form validation
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity()) {
            submitForm(event);
        }
    };
    //#endregion

    //#region Form send
    const [submitted, setSubmitted] = useState(false);

    const submitForm = async (event) => {

        event.preventDefault();
        event.stopPropagation();

        const email = event.target.email.value;
        const phone = event.target.phone.value;
        const message = event.target.message.value;

        const response = await ContactFormSend(email, phone, message);

        // console.log('response', response);

        if (response.error) {
            console.log('ContactFormSend failed');
            return;
        }

        console.log('form submitted');

        setSubmitted(true);

        setValidated(true);
        event.target.reset();
    };
    //#endregion

    //#region New form
    const handleNewForm = () => {
        setSubmitted(false);
    };
    //#endregion

    return (
        <div
            className="bg-light"
        >
            <Container
                className="py-5"
            >
                <Row>
                    <Col>
                        <h1>{data && data.details?.title}</h1>
                        <label
                            className="d-block mb-3 text-black-50"
                        >
                            {data && data.details?.text}
                        </label>
                    </Col>
                    <Col>
                        {!submitted ?
                            <_Form
                                data={data}
                                validated={validated}
                                handleSubmit={handleSubmit}
                            />
                            :
                            <_FormSubmitted
                                data={data}
                                handleClick={handleNewForm}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactForm;