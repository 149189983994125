import React, { useState } from 'react';
import { Button, Container, Form, Nav, Navbar } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSession } from '../auth/useSession';

// Style
import '../styles/Navbar.css';

// Methods
import { Logout } from '../methods/admin';

// import NavigationMenu from './NavigationMenu';

const _Navbar = ({
	changeVisibility,
}) => {

	const [searchParams] = useSearchParams();
	const { search } = useLocation();

	// React router DOM
	const navigate = useNavigate();

	// Session object
	const { setSession } = useSession();

	const path = window.location.pathname;

	const [page, _setPage] = useState(() => {
		switch (path) {
			case '/':
			case '/home':
				return 'home';

			case '/about':
				return 'about';

			default:
				return '';
		}
	});

	const handleLogout = async event => {
		event.preventDefault();

		const result = await Logout();

		if (result?.Error) {
			console.log('Ooops...something went wrong');
			return;
		}

		setSession(null);
		navigate('/lockscreen');
	};

	return (
		<Navbar
			bg='light'
			variant='light'
			sticky='top'
			className='opacity-95'
			collapseOnSelect
			expand='lg'
		>
			<Container>
				<Navbar.Brand href={`/home${search}`}>
					<img
						alt='Garden Bürgi'
						src='/logo_navbar.png'
						className='d-inline-block align-top'
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbar-nav" />
				<Navbar.Collapse id="navbar-nav">
					<Nav className='me-auto'>
						<Nav.Link href={`/home${search}`} className={page && page === 'home' ? 'active' : ''}>Home</Nav.Link>
						<Nav.Link href={`/about${search}`} className={page && page === 'about' ? 'active' : ''}>About</Nav.Link>
						<Nav.Link href="#" className="dropdown-toggle" onClick={changeVisibility}>Navigation</Nav.Link>
					</Nav>
					{process.env.REACT_APP_ENV !== 'live' && !searchParams.has('preview-token') ?
						<Navbar.Text className='me-3'>
							<span>v</span>
							<span>{process.env.REACT_APP_VERSION}</span>
						</Navbar.Text> : ''}
					{process.env.REACT_APP_ENV !== 'live' && !searchParams.has('preview-token') ?
						<Form className='d-flex' onSubmit={event => handleLogout(event)}>
							<Button variant='outline-danger' type='submit'>Logout</Button>
						</Form> : ''}
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default _Navbar;