import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { GetImageUrl } from '../../utility/ImageUtility';

/* eslint-disable */
// TODO use separate CarouselItem component
const CarouselItem = ({ data, innerRef }) => {

	// console.log('CarouselItem', data);

	return (
		<Carousel.Item identifier={data.identifier} ref={innerRef}>
			{data.link && data.link.length > 0 ?
				<a href={data.link}>
					<img
						className="d-block w-100"
						src={GetImageUrl(data.imageSrc || data.image_src)}
						alt={data.text}
					/>
					<Carousel.Caption>
						{data.text ? <h3>{data.text}</h3> : ''}
						{data.caption ? <p>{data.caption}</p> : ''}
					</Carousel.Caption>
				</a>
				:
				<>
					<img
						className="d-block w-100"
						src={GetImageUrl(data.imageSrc || data.image_src)}
						alt={data.text}
					/>
					<Carousel.Caption>
						{data.text ? <h3>{data.text}</h3> : ''}
						{data.caption ? <p>{data.caption}</p> : ''}
					</Carousel.Caption>
				</>
			}
		</Carousel.Item>
	);
};
/* eslint-enable */

const CarouselModuleComponent = ({ data }) => {

	// console.log('CarouselModuleComponent', data);

	const [carousel, setCarousel] = useState();

	useEffect(() => {
		if (!carousel) {
			setCarousel(data);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<div className='px-0'>
			{carousel && carousel.items?.length > 0 ?
				<Carousel
					indicators={carousel.items.length > 1 ? carousel.dots : false}
					controls={carousel.items.length > 1 ? carousel.controls : false}
					interval={carousel.interval}
					variant={carousel.variant}
				>
					{carousel.items.map(item => (
						<Carousel.Item key={uuidv4()} identifier={item.identifier}>
							{item.link && item.link.length > 0 ?
								<a href={item.link}>
									<img
										className="d-block w-100"
										src={GetImageUrl(item.imageSrc || item.image_src)}
										alt={item.text}
									/>
									<Carousel.Caption>
										{item.text ? <h3>{item.text}</h3> : ''}
										{item.caption ? <p>{item.caption}</p> : ''}
									</Carousel.Caption>
								</a>
								:
								<>
									<img
										className="d-block w-100"
										src={GetImageUrl(item.imageSrc || item.image_src)}
										alt={item.text}
									/>
									<Carousel.Caption>
										{item.text ? <h3>{item.text}</h3> : ''}
										{item.caption ? <p>{item.caption}</p> : ''}
									</Carousel.Caption>
								</>
							}
						</Carousel.Item>
					))}
				</Carousel>
				: ''}
		</div>
	);
};

export default CarouselModuleComponent;
