import { RunQuery } from '../utility/Fetch';

/**
 * Get the NavigationMenu by a given language
 * @param {The language of the desired content, if not provided the default will be taken (italian)} language
 * @returns The NavigationMenu object
 */
export const GetNavigationMenu = async (language) => {

	// If the language is not provided is it be set to italian => 0
	if (!language) language = 0;

	const response = await RunQuery({
		path: 'v1/Navigation/NavigationMenu',
		callType: 'get',
		data: {
			language: language,
		},
		withCredentials: true,
	})
		.then(response => {
			// console.log('RunQuery > GetNavigationMenu > response:', response);

			if (response.Error.ErrorCode) {
				return response.Error;
			}

			return response.Result;
		});

	return await response;
};