import React, { useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/** Styles */
import '../../styles/ImageModule.css';

const ImageModuleComponent = ({ data }) => {

    // console.log('ImageModuleComponent', data);

    const navigate = useNavigate();

    const marginX = data?.marginX?.endsWith('px') || data?.marginX?.endsWith('%') ? data?.marginX : null;
    const containerFluid = data?.marginX === 'fluid' ? true : false;
    const defaultMargin = data?.marginX === 'default' ? true : false;

    const [containerStyle] = useState({
        marginLeft: marginX,
        marginRight: marginX,
        maxWidth: marginX ? '-webkit-fill-available' : null,
    });

    const [imageStyle] = useState({
        objectPosition: data?.position ? data.position : null,
        maxHeight: data?.height && data?.height > 0 ? `${data.height}px` : 'auto',
    });

    const handleNavigate = () => {

        if (data?.link) {
            if (data.link.startsWith('http') ||
                data.link.startsWith('www')) {
                window.open(data.link, '_blank');
            }
            else {
                navigate(data.link);
            }
        }
    };

    return (
        <Container
            fluid={!containerFluid && !marginX && !defaultMargin ? true : containerFluid}
            className={`g-0 ${data?.link ? 'handle-navigate' : ''}`}
            style={containerStyle}
        >
            {data?.imageSrc &&
                <Image
                    className={`w-100 ${data?.fit ? data.fit : ''} ${data?.border ? 'border' : ''} ${data?.thumbnail ? 'img-thumbnail' : ''}`}
                    src={`${process.env.REACT_APP_MEDIA_URL}${data?.imageSrc}`}
                    onClick={handleNavigate}
                    height={data?.height && data?.height > 0 ? data.height : 'auto'}
                    style={imageStyle}
                />}
        </Container>
    );
};

export default ImageModuleComponent;
