import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router';

import Navbar from '../components/Navbar';
import NavigationSection from '../components/NavigationSection';

const Layout = ({
	children,
}) => {

	const [visible, setVisible] = useState(false);

	const location = useLocation();
	useEffect(() => {
		setVisible(false);
	}, [location]);

	const changeVisibility = () => {
		setVisible(!visible);
	};

	return (
		<Container fluid className='px-0'>
			<Navbar changeVisibility={changeVisibility} />
			<NavigationSection visible={visible} />
			{children}
		</Container>
	);
};

export default Layout;
