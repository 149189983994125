import cryptoJs from 'crypto-js';
import moment from 'moment';
import 'moment-timezone';
import { useState } from 'react';

// Config
import config from '../config.json';

export const useSession = () => {

    const [session, setSessionInternal] = useState(() => {

        const parsedSession = getSession();

        if (!parsedSession) {
            console.log('cookie not found!!');
            return null;
        }

        const expirationDate = moment.parseZone(parsedSession.cookie_expiration);
        const now = moment.parseZone();

        if (now.isAfter(expirationDate)) {
            console.log('cookie expired!!');
            return null;
        }

        return parsedSession;
    });

    const setSession = newSession => {
        if (newSession)
        {
            const stringifiedSession = JSON.stringify(newSession);
            const encodedStringSession = cryptoJs.AES.encrypt(stringifiedSession, config?.secret_key).toString();

            localStorage.setItem('session', encodedStringSession);
            setSessionInternal(encodedStringSession);
        }
        else {
            localStorage.removeItem('session');
            setSessionInternal(null);
        }
    };

    return { session, setSession };
};

export const getSessionCookie = () => {

    const session = getSession();

    if (!session) {
        return null;
    }

    return session.session_cookie;
};

/**
 * Intern method to retrieve the current parsed session
 * @returns Session object
 */
const getSession = () => {

    const stringSession = localStorage.getItem('session');

    if (!stringSession) {
        return null;
    }

    const decodedStringSession = cryptoJs.AES.decrypt(stringSession, config?.secret_key);

    // TODO Handle the cookie expiration

    return JSON.parse(decodedStringSession.toString(cryptoJs.enc.Utf8));
};