import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Styles
import './styles/CustomApp.css';

// Components
import PrivateRoute from './components/PrivateRoute';

// Dev views
import Lockscreen from './views/Lockscreen';

// Views
import About from './views/About';
import CMSPage from './views/CMSPage';
import HomePage from './views/HomePage';
import Layout from './views/_Layout';

import { GetNavigationMenu } from './methods/navigation';

const getNavigationRoute = (parent, previewToken, client) => {

	const children = [];

	parent.children.map(item => {
		// console.log('getNavigationRoute', parent, getChildUrl(item.url));

		if (item?.children.length > 0) {
			children.push(
				<Route
					key={uuidv4()}
					path={getChildUrl(item.url)}
				>
					<Route
						index
						element={<PrivateRoute
							previewToken={previewToken?.value}
							clientId={client?.value}>
							<Layout>
								<CMSPage url={item.url} language="0" />
							</Layout>
						</PrivateRoute>}
					/>
					{item.children ? getNavigationRoute(item, previewToken, client) : ''}
				</Route>
			);
		}
		else {
			children.push(
				<Route
					key={uuidv4()}
					path={getChildUrl(item.url)}
					element={<PrivateRoute
						previewToken={previewToken?.value}
						clientId={client?.value}>
						<Layout>
							<CMSPage url={item.url} language="0" />
						</Layout>
					</PrivateRoute>}
				/>
			);
		}
	});

	return (children);
};

const getChildUrl = (url) => {
	const urls = url.split('/');
	return urls[urls.length - 1];
};

function App() {

	// To test => ?preview-token=2f313006-4239-4714-ac71-3f2ac3c19261&client=82611ede-316c-4473-be27-548133a4e4bd
	const searchParams = window.location.search.substring(1).split('&').map(param => {
		const strings = param.split('=');
		return {
			key: strings[0],
			value: strings[1]
		};
	});

	const previewToken = searchParams.filter(item => { return item.key === 'preview-token'; })[0];
	const client = searchParams.filter(item => { return item.key === 'client'; })[0];

	const [navigation, setNavigation] = useState();

	useEffect(() => {
		if (!navigation) {
			GetNavigationMenu()
				.then(response => {
					if (!response?.ErrorCode) {
						setNavigation(response);
					}
				});
		}
	});

	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/lockscreen" element={<Lockscreen previewToken={previewToken?.value} clientId={client?.value} />} />
					<Route path="/" element={<PrivateRoute previewToken={previewToken?.value} clientId={client?.value}><Layout><HomePage /></Layout></PrivateRoute>} />
					<Route path="/home">
						<Route index element={<PrivateRoute previewToken={previewToken?.value} clientId={client?.value}><Layout><HomePage /></Layout></PrivateRoute>} />
						<Route path="about" element={<PrivateRoute previewToken={previewToken?.value} clientId={client?.value}><Layout><CMSPage url='/home/about' language="0" /></Layout></PrivateRoute>} />
					</Route>
					<Route path="/about" element={<PrivateRoute previewToken={previewToken?.value} clientId={client?.value}><Layout><About /></Layout></PrivateRoute>} />
					{/* CMS Routes */}
					<Route>
						{navigation && navigation.map(menu => {
							if (menu?.children.length > 0) {
								return (
									<Route
										key={uuidv4()}
										path={menu.url.slice(1)}
									>
										<Route
											index
											element={<PrivateRoute
												previewToken={previewToken?.value}
												clientId={client?.value}>
												<Layout>
													<CMSPage url={menu.url} language="0" />
												</Layout>
											</PrivateRoute>}
										/>
										{menu.children ? getNavigationRoute(menu, previewToken, client) : ''}
									</Route>
								);
							}
							else {
								return (
									<Route
										key={uuidv4()}
										path={menu.url.slice(1)}
										element={<PrivateRoute
											previewToken={previewToken?.value}
											clientId={client?.value}>
											<Layout>
												<CMSPage url={menu.url} language="0" />
											</Layout>
										</PrivateRoute>}
									/>
								);
							}
						})}
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
