import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/** Styles */
import '../../styles/TextImageModule.css';

const TextImageModuleComponent = ({ data }) => {

    // console.log('TextImageModuleComponent', data);

    const navigate = useNavigate();

    const handleNavigate = () => {

        if (data?.link) {
            if (data.link.startsWith('http') ||
                data.link.startsWith('www')) {
                window.open(data.link, '_blank');
            }
            else {
                navigate(data.link);
            }
        }
    };

    return (
        <div className='py-3'>
            <div className={data?.frame ? 'module-frame' : ''}>
                <Row onClick={handleNavigate} className={`g-0 ${data?.link ? 'handle-navigate' : ''}`}>
                    <Col
                        xl={4}
                        lg={4}
                        md={5}
                        sm={6}
                        className={(!data?.position || data?.position === 'left') ? 'ps-0' : 'pe-0 order-last'}
                    >
                        {data?.imageSrc &&
                            <Image
                                className='img-fluid w-100'
                                src={`${process.env.REACT_APP_MEDIA_URL}${data?.imageSrc}`}
                            />}
                    </Col>
                    <Col
                        xl={8}
                        lg={8}
                        md={7}
                        sm={6}
                        className={(!data?.position || data?.position === 'left') ? 'pe-0' : 'ps-0 order-first'}
                    >
                        <div
                            className={(!data?.position || data?.position === 'left') ? 'ps-2' : 'pe-2'}
                            dangerouslySetInnerHTML={{ __html: data?.text }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TextImageModuleComponent;
