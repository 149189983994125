import { RunQuery } from '../utility/Fetch';

/**
 * Send the contact form
 * @param {The email address, mandatory} email
 * @param {The phone numeber} phone
 * @param {The message, mandatory} message
 * @returns
 */
export const ContactFormSend = async (email, phone, message) => {

    return {
        error: false,
        message: 'Modulo inviato con successo'
    };

    /* eslint-disable no-unreachable */
    const response = await RunQuery({
        path: 'v1/ContactForm/ContactFormSend',
        callType: 'post',
        data: {
            email: email,
            phone: phone,
            message: message,
        },
        withCredentials: true
    })
    .then(response => {
        // console.log('RunQuery > ContactFormSend > response:', response);

        if (response.Error.ErrorCode)
        {
            return response.Error;
        }

        return response.Result;
    });

    return await response;
};