import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const FooterModule = ({ data }) => {

    if (!data) return;

    return (
        <Col sm={4}>
            <h3>{data.title}</h3>
            {data.rows.map(row => (
                <label
                    className="d-block"
                    key={uuidv4()}
                >
                    {row.url ?
                        <a href={row.url}>
                            <div dangerouslySetInnerHTML={{ __html: row.label }} />
                        </a>
                        :
                        <div dangerouslySetInnerHTML={{ __html: row.label }} />
                    }
                </label>
            ))}
        </Col>
    );
};

const Footer = ({ data }) => {

    if (!data) return;

    // console.log('footer', data);

    return (
        <div
            className="bg-light pt-3 pb-1"
        >
            <Container
                className="py-4"
            >
                <Row className="mb-4">
                    <FooterModule data={data && data.left} />
                    <FooterModule data={data && data.middle} />
                    <FooterModule data={data && data.right} />
                </Row>
                <Row>
                    <Col
                        className="d-flex justify-content-center"
                    >
                        <label className="text-black-50">
                            <div dangerouslySetInnerHTML={{ __html: data && data.credits }} />
                        </label>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;